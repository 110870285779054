import { AuthError } from '@auth/auth.enum';
import { UserInfo } from '@shared/models/auth.model';

export class InitAuthentication {
  static readonly type = '[Auth] Init Authentication';

  constructor(readonly disableAnonymous?: boolean) {}
}

export class UpdateUserOnAuthTokenChange {
  static readonly type = '[Auth] Login';
  constructor(readonly userInfo: UserInfo) {}
}

export class RemoveUserOnAuthTokenChange {
  static readonly type = '[Auth] Logout';
}

export class SaveTokenOnAuthTokenChange {
  static readonly type = '[Auth] Update Token';

  constructor(readonly authToken: string) {}
}

export class SaveClaimsOnAuthTokenChange {
  static readonly type = '[Auth] Change Claims';

  constructor(readonly authClaims: any) {}
}

export class MonitorLoginExpiration {
  static readonly type = '[Auth] Monitor Login Expiration';

  constructor(readonly userUid: string) {}
}

export class SignUpAuthenticated {
  static readonly type = '[Auth] Sign Up Authenticated';

  constructor() {}
}

export class SignOutWithRedirect {
  static readonly type = '[Auth] Sign Out With Redirect';

  constructor(public signIn?: boolean | null) {}
}

export class SignOutWithoutRedirect {
  static readonly type = '[Auth] Sign Out Without Redirect';
}

export class CancelGoogleSignup {
  static readonly type = '[Auth] Cancel Google Signup';
}

export class CancelEmailSignup {
  static readonly type = '[Auth] Cancel Email Signup';
}

export class ChangePassword {
  static readonly type = '[Auth] Change Password';
  constructor(readonly email: string) {}
}

export class SendPasswordResetEmail {
  static readonly type = '[Auth] Send Password Reset Email';
  constructor(readonly email: string) {}
}

export class SendVerificationEmail {
  static readonly type = '[Auth] Send Verification Email';
  constructor(
    readonly redirect?: string,
    readonly lang?: string,
  ) {}
}

export class GetInvite {
  static readonly type = '[Auth] Get Invite';
  constructor(readonly inviteKey?: string) {}
}

export class ResetUserInfo {
  static readonly type = '[Auth] Reset User Info';
}

export class GoToSignIn {
  static readonly type = '[Auth] Go to SignIn';
  constructor(
    readonly redirect?: string,
    readonly mode?: string,
    readonly activate?: string,
    readonly lang?: string,
  ) {}
}

export class GoToSignUp {
  static readonly type = '[Auth] Go to SignUp';
  constructor(
    readonly redirect?: string,
    readonly activate?: string,
  ) {}
}

export class GoToLogoutUrl {
  static readonly type = '[Auth] Go to Logout Url';
  constructor(readonly logoutUrl: string) {}
}

export class AccessDenied {
  static readonly type = '[Auth] Access Denied';

  constructor(readonly message?: string) {}
}

export class LinkPassword {
  static readonly type = '[Auth] Link Password';
  constructor(
    readonly newPassword: string,
    readonly oldPassword?: string,
  ) {}
}

export class UnlinkPassword {
  static readonly type = '[Auth] Unlink Password';
}

export class PasswordUpdated {
  static readonly type = '[Auth] Password Updated';
}

export class LinkGoogle {
  static readonly type = '[Auth] Link Google';
}

export class UnlinkGoogle {
  static readonly type = '[Auth] Unlink Google';
}

export class AuthenticationError {
  static readonly type = '[Auth] Error';

  constructor(readonly code?: AuthError) {}
}
